import React from 'react'
import { Link } from 'gatsby'
import { blogURI } from '../../globals'
import FluidImage from './fluid-image'
import moment from 'moment/moment'

// const FullSpacedHr = styled.div`
//   margin: 1.5em 0;
//   border: 3px solid #efefef;
// `

const PostEntry = ({ post }) => {
  const { uri, date, title, featuredImage, excerpt } = post

  return (
    <div
      style={{
        marginBottom: '30px',
        padding: '25px',
        background: '#10435e',
      }}
    >
      <header style={{ marginBottom: '15px' }}>
        <Link to={`${blogURI}/${uri}/`}>
          <h2
            style={{
              marginBottom: '10px',
              fontWeight: '800',
              fontSize: '1.75rem',
            }}
          >
            {title}
          </h2>
          <small> Posted {moment(date).format(`MMMM D, YYYY`)}</small>
          <FluidImage image={featuredImage} style={{ margin: 0 }} />
        </Link>
      </header>

      <div
        style={{
          fontSize: '1.15rem',
        }}
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <Link to={`${blogURI}/${uri}/`}>
        <p
          style={{
            marginBottom: '0px',
            fontWeight: '800',
            fontSize: '1.15rem',
          }}
        >
          Read More >
        </p>
      </Link>
    </div>
  )
}

export default PostEntry
